import { publicConfig } from "./config";

const { MAIN_SITE_DOMAIN } = publicConfig;

const routes = {
	purchase: {
		configSubscription: `//${MAIN_SITE_DOMAIN}/ecommerce/buyLabLicense/`,
	},
	auth: {
		login: `//${MAIN_SITE_DOMAIN}/main/login`,
		logout: `//${MAIN_SITE_DOMAIN}/myaccount/sign-out`,
		exchange: "/auth/exchange",
	},
	myAccount: `//${MAIN_SITE_DOMAIN}/myaccount`,
	invoice: {
		pay: "/invoice/pay",
		invalid: "/invoice/invalid",
		success: "/invoice/pay-success",
	},
	payQuote: {
		index: "/invoice/view",
		invalidQuote: "/invoice/view/invalid",
		requestPOSuccess: "/invoice/view/success",
	},
	account: {
		subscription: {
			activations: "/account/subscription/activations",
			index: "/myaccount/subscription/",
			entitlements: "/account/subscription/entitlements",
			machineTokens: "/account/subscription/tokens",
			sso: "/account/subscription/sso",
			scim: "/account/subscription/scim",
			domains: "/account/subscription/domains",
		},
		entitlements: {
			activations: "/account/entitlements/activations",
		},
		preferences: "/account/preferences",
	},
	errors: {
		403: "/403",
		404: "/404",
		default: "/error",
	},
	customers: "/customers",
	resources: "/resources",
	contact: {
		index: "/contact",
		success: "/contact/success",
	},
	graphPadPrism: "/features",
	updates: "/updates",
	prismAcademy: "/prism-academy",
	about: "/company",
	features: {
		prismAnova: "/features/prism-anova",
		prismNonlinearRegression: "/features/prism-nonlinear-regression",
		prismSurvivalAnalysis: "/features/prism-survival-analysis",
		powerAnalysis: "/features/power-analysis",
	},
	ssoGuide: "/support/faq/sso-guide/",
	scimGuide: "/support/faq/scim-guide/",
	domainsGuide: "/support/faq/domains-guide/",
	professorCourseLicenses: "/free-course-licenses",
	external: {
		index: `//${MAIN_SITE_DOMAIN}/`,
		howToBuy: `//${MAIN_SITE_DOMAIN}/how-to-buy/`,
		support: `//${MAIN_SITE_DOMAIN}/support/`,
		cart: `//${MAIN_SITE_DOMAIN}/ecommerce/`,
		termsOfService: `//${MAIN_SITE_DOMAIN}/company/terms/`,
		privacyPolicy: `//${MAIN_SITE_DOMAIN}/company/privacy-policy/`,
		contactUs: `//${MAIN_SITE_DOMAIN}/contact-us/`,
		freeTrial: `//${MAIN_SITE_DOMAIN}/demos/`,
		instat: `//${MAIN_SITE_DOMAIN}/scientific-software/instat/`,
		statMate: `//${MAIN_SITE_DOMAIN}/scientific-software/statmate/`,
		student: `//${MAIN_SITE_DOMAIN}/how-to-buy/student/`,
		graphPadChina: "https://www.graphpad-prism.cn",
		quickCalcs: `//${MAIN_SITE_DOMAIN}/quickcalcs/`,
		statisticsGuide: `//${MAIN_SITE_DOMAIN}/guides/prism/latest/statistics/`,
		curveFittingGuide: `//${MAIN_SITE_DOMAIN}/guides/prism/latest/curve-fitting/`,
		prismUserGuide: `//${MAIN_SITE_DOMAIN}/guides/prism/latest/user-guide/`,
		careers: "https://www.dotmatics.com/about-careers",
		portfolio: "https://www.dotmatics.com/portfolio",
		dotmatics: "https://www.dotmatics.com",
		contacts: `//${MAIN_SITE_DOMAIN}/support`,
		machineAccessTokenActivation: `//${MAIN_SITE_DOMAIN}/support/faq/machine-access-token-activation/`,
		licenseLimits: `//${MAIN_SITE_DOMAIN}/support/faq/license-limits/`,
		donotsell: "https://donotsell.dotmatics.com/hc/en-us/requests/new",
		platform: "//www.dotmatics.com/platform",
		products: {
			prism: "//www.graphpad.com/",
			geneious: "//www.geneious.com/",
			snapgene: "//www.snapgene.com/",
			labArchives: "//www.labarchives.com/",
			proteinMetrics: "//proteinmetrics.com/",
			omiq: "//www.omiq.ai/",
			fcsExpress: "//denovosoftware.com/",
			nQuery: "//www.statsols.com/",
		},
	},
	cloud: "/cloud",
	enterprise: "/enterprise",
	cloudEarlyAccess: "/cloud/success",
};
export default routes;
